<template>
  <div id="crm-contact" class="page">
    <b-card class="card-custom mb-3">
      <b-card-text v-if="contact">
        <b-row>
          <b-col lg="6" md="12" sm="12" class="text-left">
            <div class="float-left">
              <BIconPersonLinesFill style="color: #666;" class="mr-3" font-scale="4"/>
            </div>
            <strong>{{ contact.fullName }}</strong>
            <div class="small">E-Mail: {{ contact.email }}</div>
            <div class="small">Tel. {{ contact.phoneNumber }}</div>
          </b-col>
          <b-col lg="6" md="12" sm="12" class="text-right">
            <div class="small">on {{ contact.createdAt }}</div>
            <div v-if="contact.channel">channel: <strong>{{ contact.channel.name }}</strong></div>
          </b-col>
        </b-row>
      </b-card-text>
      <b-spinner v-else label="Loading..." small></b-spinner>
    </b-card>

    <b-card v-if="contact" no-body>
      <b-tabs card>
        <b-tab v-if="user.hasPermission('crm:contact.notes.search')" title="Notes">
          <Notes :contact="contact"/>
        </b-tab>
        <!--
        <b-tab title="Appointments">
          Appointments
        </b-tab>
      -->
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import {BIconPersonLinesFill} from 'bootstrap-vue';
import moment from 'moment';
export default {
    props: ['user'],
    components: {
      BIconPersonLinesFill,
      Notes: () => import('./../../components/Notes/Notes')
    },
    data(){
        return {
          contact: null
        };
    },

    methods:{
      notifyParent(){
        let data = {active: "crm.contacts"};
        this.$emit('set-sidebar',data);
        this.$emit('set-navbar',data);
      },

      parseContact(data){
        data.channel = this.channels.find(o => o.id === data.source);
        data.createdAt = moment(data.createdAt).format('DD/MM/YYYY HH:mm:ss');

        return data;
      },

      getContact(contactId){
        return this.$api.get(`crm/contact/${contactId}`)
        .then(({data}) => {
          this.contact = this.parseContact(data);
        })
      }
    },

    mounted(){
        this.notifyParent();
        this.getContact(this.$route.params.contactId);
    },

    computed: {
      channels(){
        return this.$store.getters['Callcenter/getChannels'];
      },
    }
};
</script>
